import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import SEO from "components/Seo";
import { capitalise } from "utils/capitalise";
import { formatSpaces } from "utils/formatSpaces";
import { usePresentationContext } from "../../context/PresentationContext";

const Component = ({ data }) => {
  const { name, specialisation1, specialisation2, biography, picture } = data.allStrapiTeamMember.edges[0].node;

  const specialisations = [specialisation1, specialisation2].filter(Boolean);
  const hasSpecialisations = specialisations.length > 0;

  const { html } = biography.data.childrenMarkdownRemark[0];

  // @TODO: placeholder image?
  const { alternativeText, localFile } = picture ?? {};

  const image = getImage(localFile);

  const { PresentationComponent } = usePresentationContext();

  return (
    <PresentationComponent>
      <main className="main max md:px-m">
        <article>
          <section>
            <div className="flex flex-col gap-y-4 gap-x-8 lg:gap-x-10 mb-8 md:flex-row">
              <div className="w-full md:w-6/12">
                <GatsbyImage image={image} alt={alternativeText} />
              </div>
              <div className="w-full md:w-6/12 px-4 md:pl-0">
                <div className="mb-4">
                  <h1 className="h mt-0 mb-1">{name}</h1>
                  {hasSpecialisations && (
                    <div className="border-l-[3px] border-solid border-accent/60 pl-2">
                      <div className="text-sm text-blue-900 font-lato font-bold">
                        {specialisations.map((spec, ix) => <span key={ix} className="block">{spec}</span>)}
                      </div>
                    </div>
                  )}
                </div>
                <div dangerouslySetInnerHTML={{ __html: formatSpaces(html) }} />
              </div>
            </div>
          </section>
        </article>
      </main>
    </PresentationComponent>
  );
};

export const Head = ({ data }) => {
  const { seo } = data.allStrapiTeamMember.edges[0].node;

  // TODO: meta og image?
  return <SEO title={seo.metaTitle} description={seo.metaDescription} />;
}

export const pageQuery = graphql`
  query TeamMemberQuery($id: String) {
    allStrapiTeamMember(filter: {id: {eq: $id}}) {
      edges {
        node {
          id
          title
          name
          specialisation1
          specialisation2
          biography {
            data {
              childrenMarkdownRemark {
                html
              }
            }
          }
          seo {
            metaDescription
            metaTitle
          }
          locale
          picture {
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 600
                  aspectRatio: 0.72
                  transformOptions: {fit: COVER, cropFocus: CENTER}
                )
              }
            }
          }
        }
      }
    }
  }
`;

export default Component;
